import { LegacyCard, Popover, Select, Spinner, Text } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import React, { useState } from "react";

import { SubscriptionProgramCard } from "@vendor-app/app/_sharedComponents/SubscriptionProgramCard";

import * as S from "./styles";
import { NoSubscriptionsCard } from "../NoSubscriptionsCard";

export interface ISelectSubscriptionProgramSectionProps {
  sellingPlansLoading: boolean;
  sellingPlanGroups: ISmartrrSellingPlanGroup[];
  isOpenPopover: boolean;
  setIsOpenPopover: (arg0: boolean) => void;
}

interface ISellingPlansOptions {
  label: string;
  value: string;
}

export function SelectSubscriptionProgramSection({
  sellingPlansLoading,
  sellingPlanGroups,
  isOpenPopover,
  setIsOpenPopover,
}: ISelectSubscriptionProgramSectionProps): JSX.Element {
  const [sellingPlansOptions, setSellingPlansOptions] = useState<ISellingPlansOptions[]>([]);
  const [selectedSubscriptionProgram, setSelectedSubscriptionProgram] = useState<string>("");
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<string>("");

  const onSubscriptionProgramSelect = (program: ISmartrrSellingPlanGroup) => {
    if (program && program?.sellingPlans) {
      const options: ISellingPlansOptions[] = [];

      program.sellingPlans.map(sp => {
        options.push({
          label: sp?.options[0] || sp?.name,
          value: sp?.options[0] || sp?.name,
        });
      });

      setSellingPlansOptions(options);
      setSelectedSubscriptionProgram(program.name);
      setIsOpenPopover(false);
    }
  };

  const onSellingPlanSelect = (value: string) => {
    setSelectedSubscriptionPlan(value);
  };

  return (
    <S.SectionWrapper data-testid="subscription-program-section-container">
      <Box mb={1}>
        <Text variant="headingMd" as="h2">
          1. Select Subscription Program
        </Text>
      </Box>
      {sellingPlansLoading ? (
        <Box mt={4} justifyContent="center" alignItems="center" data-testid="spinner-container">
          <Spinner />
        </Box>
      ) : sellingPlanGroups.length === 0 ? (
        <NoSubscriptionsCard />
      ) : (
        <LegacyCard>
          <LegacyCard.Section>
            <Box mb={0.7}>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                Subscription Program
              </Text>
            </Box>
            <S.PopoverWrapper onClick={() => setIsOpenPopover(!isOpenPopover)}>
              <Popover
                active={isOpenPopover}
                onClose={() => setIsOpenPopover(false)}
                fullWidth
                activator={
                  <Select
                    label="Select one"
                    labelHidden={!!selectedSubscriptionProgram}
                    labelInline={!selectedSubscriptionProgram}
                    // Set input value as placeholder because in some reason value prop doesn't work here
                    placeholder={selectedSubscriptionProgram}
                  />
                }
              >
                {sellingPlanGroups.map(group => (
                  <SubscriptionProgramCard
                    key={group.shopifyId}
                    group={group}
                    onSubscriptionProgramSelect={onSubscriptionProgramSelect}
                  />
                ))}
              </Popover>
            </S.PopoverWrapper>
            <Box mt={1.5} mb={0.7}>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                Subscription Plan
              </Text>
            </Box>
            <Select
              label="Select one"
              disabled={!sellingPlansOptions.length}
              labelHidden={!!sellingPlansOptions.length}
              labelInline={!sellingPlansOptions.length}
              options={sellingPlansOptions}
              onChange={onSellingPlanSelect}
              value={selectedSubscriptionPlan}
            />
          </LegacyCard.Section>
        </LegacyCard>
      )}
    </S.SectionWrapper>
  );
}
