import styled from "styled-components";

export const SectionWrapper = styled.div`
  border-top: var(--p-border-width-1) solid var(--p-color-border-subdued);
  margin-top: 22px;
  padding-top: 20px;
`;

export const PopoverWrapper = styled.div`
  select option {
    display: none;
  }
`;
