import styled from "styled-components";

export const ProgramCardWrapper = styled.div`
  > .Polaris-LegacyCard {
    border-radius: 0;
    .Polaris-LegacyCard__Section {
      border: 0.625rem solid white;
      padding: 0.625rem;
      border-radius: var(--p-border-radius-4);
    }
    &:hover .Polaris-LegacyCard__Section {
      background-color: #f2f7fe;
    }
  }
`;

export const CountsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  .Polaris-LegacyCard {
    background: #1a1c1d;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 9px 11px;
    margin-top: 0px;
    margin-left: 10px;
  }
  .Polaris-LegacyCard + .Polaris-LegacyCard {
    margin-top: 0px;
  }
`;
