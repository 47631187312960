import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { createLazyFileRoute } from "@tanstack/react-router";
import { AdminCreateSubscriptionRouteNew } from "@vendor-app/app/AdminRoute/AdminCreateSubscriptionRouteNew";
import { AuthorizedRoute } from "@vendor-app/app/AdminRoute/components/authorization/AuthorizedRoute";
import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";

export const Route = createLazyFileRoute("/admin/subscriptions/createnew")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  return AuthorizedRoute(FeatureEnum.CORE_SUBSCRIPTIONS, <AdminCreateSubscriptionRouteNew />);
}
