import { useApolloClient } from "@apollo/client";
import { Button, LegacyCard, Text } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { IPaymentMethod } from "@smartrr/shared/entities/PaymentMethod";
import { useBoolean } from "@smartrr/shared/hooks/useBoolean";
import { queryShopifyCustomer, queryShopifyCustomers } from "@smartrr/shared/shopifyGraphQL/customer";
import { captureException } from "@smartrr/shared/utils/captureException";
import { debounce } from "lodash";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { AutoCompleteWithTextField } from "@vendor-app/app/AdminRoute/components/elements/AutocompleteWithTextField";
import { checkIsCustomerErased } from "@vendor-app/utils/checkIsCustomerErased";

import { MAX_CUSTOMER_TO_DISPLAY, getCustomers } from "../..";
import { ImportCustomerModal } from "../../../../AdminCreateSubscriptionRoute/libs";

export interface ICustomerSelectorInput {
  loadCustomerPaymentMethods: (relationshipId: string, shopifyId: string, custRelId: string) => void;
  setIsShippingAvailable: (isAvailable: boolean) => void;
  setFieldValue: (filedName: string, value: any) => void;
  updateShopifyPaymentMethodsState: (paymentMethods: IPaymentMethod[]) => void;
}

export function CustomerSelectorInput({
  loadCustomerPaymentMethods,
  setFieldValue,
  setIsShippingAvailable,
  updateShopifyPaymentMethodsState,
}: ICustomerSelectorInput): JSX.Element {
  const [autoCompleteCustomers, setAutoCompleteCustomers] = useState<ICustomerRelationshipShallow[]>([]);
  const [isModalOpen, openModal, closeModal] = useBoolean(false);
  const [isLoading, setLoading] = useState(false);
  const [isAvailableOnShopify, setAvailableOnShopify] = useState(false);
  const [hasResults, setHasResults] = useState(true);
  const [shopifyCustomerId, setShopifyCustomerId] = useState("");
  const client = useApolloClient();

  useEffect(() => {
    void fetchInitialCustomers();
  }, []);

  const options = useMemo(
    () =>
      autoCompleteCustomers.map(item => ({
        label: (
          <div>
            {item.firstName || ""} {item.lastName || ""}
            <Text variant="bodyMd" color="subdued" as="p">
              {item.email || ""}
            </Text>
          </div>
        ),
        value: item.id,
        email: item.email,
      })),
    [autoCompleteCustomers]
  );

  const setFieldValues = <T, K extends keyof T>(objToUse: T, mapObject: Record<string, K>) => {
    for (const [fieldName, key] of Object.entries(mapObject)) {
      setFieldValue(fieldName, objToUse[key] || "");
    }
  };

  const setShippingInfo = async (customer?: ICustomerRelationshipShallow) => {
    if (customer) {
      setFieldValues(customer, {
        email: "email",
        firstName: "firstName",
        lastName: "lastName",
      });

      try {
        const res = await queryShopifyCustomer(customer.shopifyId!, client as any);

        if (res.type === "success") {
          const defaultAddress = res.body.data.customer?.defaultAddress;

          if (defaultAddress) {
            setFieldValues(defaultAddress, {
              address1: "address1",
              address2: "address2",
              zip: "zip",
              city: "city",
              province: "provinceCode",
              country: "countryCodeV2",
              phone: "phone",
            });
          }
        }
      } catch (error) {
        captureException("Error setting shipping info", error);
      }
      setIsShippingAvailable(true);
    }
  };

  const onEmailSelect = (customerIds: string[]) => {
    const [selectedCustomerId] = customerIds;
    const customer = autoCompleteCustomers.find(customer => customer.id === selectedCustomerId);

    loadCustomerPaymentMethods(selectedCustomerId, customer?.shopifyId!, customer?.id!);
    setShippingInfo(customer);
  };

  const checkResultsOnShopify = async (email: string, shopCustomersCount: number) => {
    if (!email || shopCustomersCount) {
      return;
    }

    setHasResults(false);

    const resp = await queryShopifyCustomers(client, `email:${email}`, 1);

    if (resp.type === "success" && resp.body.data.customers.edges.length) {
      setAvailableOnShopify(true);
      setShopifyCustomerId(resp.body.data.customers.edges[0].node.id);
    } else {
      setAvailableOnShopify(false);
    }
  };

  const fetchInitialCustomers = async () => {
    setLoading(true);

    try {
      const res = await getCustomers("", { orderBy: { email: "ASC" } });
      if (res.type === "success") {
        const results = res.body.data || [];

        const editCustomers = (customers: ICustomerRelationshipShallow[]) => {
          if (customers.length) {
            const filterdCustomers = customers.filter(customer => !checkIsCustomerErased(customer));
            filterdCustomers.length = MAX_CUSTOMER_TO_DISPLAY;
            return filterdCustomers;
          }
          return [];
        };

        setAutoCompleteCustomers(editCustomers(results));
      }
    } catch (error) {
      captureException("Error fetching customers", error);
    }
    setLoading(false);
  };

  const onEmailChange = useCallback(
    debounce(async (email: string) => {
      setIsShippingAvailable(false);
      setHasResults(true);

      if (!email) {
        setAutoCompleteCustomers([]);
        return;
      }

      setLoading(true);

      try {
        const res = await getCustomers(email);
        if (res.type === "success") {
          const results = res.body.data;
          setAutoCompleteCustomers(results.filter(customer => !checkIsCustomerErased(customer)));

          if (results.length === 1 && results[0]!.email === email) {
            void setShippingInfo(results[0]);
          }

          void checkResultsOnShopify(email, results.length);
        }
      } catch (error) {
        captureException("Error fetching customer", error);
      }

      setLoading(false);
    }, 250),
    []
  );

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <div style={{ width: "0", height: "0" }} data-testid="customer-autocomplete" />
        <AutoCompleteWithTextField
          onSelect={onEmailSelect}
          onChange={onEmailChange}
          options={options}
          loading={isLoading}
          willLoadMoreResults
        />
        {hasResults ? null : (
          <Fragment>
            <Box mt={1} mb={+isAvailableOnShopify}>
              Seems like customer with provided email doesn{"'"}t exist in Smartrr{" "}
              {isAvailableOnShopify ? null : "and Shopify"}
            </Box>
            {isAvailableOnShopify ? <Button onClick={openModal}>Import from Shopify</Button> : null}
          </Fragment>
        )}
      </LegacyCard.Section>
      <ImportCustomerModal
        open={isModalOpen}
        shopifyCustomerId={shopifyCustomerId}
        onClose={closeModal}
        setShippingInfo={setShippingInfo}
        updateShopifyPaymentMethodsState={updateShopifyPaymentMethodsState}
      />
    </LegacyCard>
  );
}
