import { ISmartrrCountry } from "@smartrr/shared/interfaces/Country";
import { CountryCode } from "@smartrr/shared/shopifyGraphQL/api";
import * as yup from "yup";

export function createValidationSchema(countriesList: ISmartrrCountry[]) {
  return yup.object().shape({
    shopifyCustomerId: yup.string(),
    stripeCustomerId: yup.string(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    address1: yup.string().required(),
    address2: yup.string(),
    city: yup.string().required(),
    country: yup.mixed<CountryCode>().oneOf(Object.values(CountryCode)),
    phone: yup.string(),
    province: yup.string().when("country", {
      is: countryCode => !!countriesList.find(c => c.countryCode === countryCode)?.provinces.length,
      then: yup.string().required("Required"),
    }),
    zip: yup.string().when("country", {
      is: countryCode => !!countriesList.find(c => c.countryCode === countryCode)?.zipRequired,
      then: yup.string().required("Required"),
    }),
    email: yup.string().required(),
  });
}
