import { LegacyCard, Text } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import React from "react";

export const NoSubscriptionsCard = (): JSX.Element => {
  return (
    <LegacyCard sectioned>
      <Box
        vertical
        py={6}
        px={1}
        alignItems="center"
        justifyContent="center"
        data-testid="no-subscription-container"
      >
        <Text variant="bodyMd" as="p">
          You don&#39; t have any subscription programs yet.
        </Text>
      </Box>
    </LegacyCard>
  );
};
