export { extractErrorMessageFromHTMLPage } from "./extractErrorMessageFromHTMLPage";
export { createValidationSchema } from "./createValidationSchema";
import { SelectGroup, SelectOption } from "@shopify/polaris";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import { IPricingPolicyCycleAdjustment } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { SellingPlanPricingPolicyAdjustmentType } from "@smartrr/shared/shopifyGraphQL/api";
import { IShopifyLiquidSellingPlanWithGroupIdAndName } from "@smartrr/shared/shopifyLiquid/selling_plan_group";
import { ISerializedPaginatedQuery } from "@smartrr/shared/utils/paginatedQuery";
import { groupBy } from "lodash";

import { typedFrontendVendorApi } from "@smartrr/vendor-portal/src/utils/typedFrontendVendorApi";

export function getItemName(variant: IPurchasableVariant, purchasable: IPurchasable): string {
  return `${purchasable.purchasableName}${
    variant.purchasableVariantName ? ` - ${variant.purchasableVariantName}` : ""
  }`;
}

export function getItemImageUrl(variant: IPurchasableVariant, purchasable: IPurchasable): string | undefined {
  return variant.purchasableVariantSmallImages?.[0] || purchasable.purchasableSmallImages?.[0];
}

export function getGroupedSellingPlan(array: IShopifyLiquidSellingPlanWithGroupIdAndName[]) {
  const groups = groupBy(array, "group_name");
  const options: (SelectGroup | SelectOption)[] = Object.entries(groups).map(([groupName, items]) => ({
    options: items.map(sp => ({
      label: sp.name,
      value: sp.id.toString(),
    })),
    title: groupName,
  }));

  options.unshift({ label: "Select", value: "" });

  return options;
}

export const getPriceAdjustmentForSelectedSellingPlan = (
  availableSellingPlans: IShopifyLiquidSellingPlanWithGroupIdAndName[],
  sellingPlanGroups: ISmartrrSellingPlanGroup[],
  selectedPlanId: number | null
): IPricingPolicyCycleAdjustment => {
  const selectedSellingPlan = availableSellingPlans.find(plan => plan.id === selectedPlanId);

  if (!selectedSellingPlan) {
    return {
      adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
      adjustmentValue: { percentage: 0 },
    };
  }

  const selectedShopifyPlan = sellingPlanGroups
    .flatMap(group => group.sellingPlans)
    .find(plan => plan.shopifyNumericId === selectedPlanId);

  const policy = selectedShopifyPlan!.pricingPolicies[0];

  if (policy.adjustmentType === "PERCENTAGE") {
    return {
      adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
      adjustmentValue: policy.adjustmentValue,
    };
  }

  return {
    adjustmentType: policy.adjustmentType as
      | SellingPlanPricingPolicyAdjustmentType.FixedAmount
      | SellingPlanPricingPolicyAdjustmentType.Price,
    adjustmentValue: { fixedValue: policy.adjustmentValue.amount },
  };
};

export const getShopifySellingPlan = (
  availableSellingPlans: IShopifyLiquidSellingPlanWithGroupIdAndName[],
  sellingPlanGroups: ISmartrrSellingPlanGroup[],
  selectedPlanId: number | null
) => {
  const availablePlan = availableSellingPlans.find(plan => plan.id === selectedPlanId);

  if (!availablePlan) {
    return undefined;
  }

  return sellingPlanGroups
    .flatMap(group => group.sellingPlans)
    .find(plan => plan.shopifyNumericId === selectedPlanId);
};

export const getCustomers = (
  email: string,
  orderBy?: { [key: string]: NonNullable<ISerializedPaginatedQuery["orderBy"]> }
) => {
  return typedFrontendVendorApi.getReq("/customer-relationship", {
    query: {
      filterLike: { email },
      ...orderBy,
    },
  });
};
