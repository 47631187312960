import { Grid } from "@mui/material";
import { LegacyCard, LegacyStack, Text } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import {
  IEnabledShopifyProductIdToShopifyVariantIdMap,
  ISmartrrSellingPlanGroup,
} from "@smartrr/shared/entities/SellingPlanGroup";
import React from "react";

import { ProductsImages } from "./components/ProductsImages";
import * as S from "./styles";

export interface ISubscriptionProgramCardProps {
  group: ISmartrrSellingPlanGroup;
  onSubscriptionProgramSelect: (arg0: ISmartrrSellingPlanGroup) => void;
}

export function SubscriptionProgramCard({
  group,
  onSubscriptionProgramSelect,
}: ISubscriptionProgramCardProps): JSX.Element {
  const getAllProductsIds = (groupProductIds: IEnabledShopifyProductIdToShopifyVariantIdMap) => {
    // returning all Products Ids even if only variant is chosen
    const NumberIds: number[] = [];

    for (const id of Object.keys(groupProductIds)) {
      if (typeof parseInt(id) === "number") {
        NumberIds.push(parseInt(id));
      }
    }

    return NumberIds;
  };

  return (
    <S.ProgramCardWrapper
      onClick={() => onSubscriptionProgramSelect(group)}
      data-testid="subscription-program-card-container"
    >
      <LegacyCard key={group.shopifyId} sectioned>
        <Grid container spacing="20px">
          <Grid item xs={12} md={7}>
            <Box mb={0.5}>
              <Text variant="bodyLg" as="p" fontWeight="semibold">
                {group.name}
              </Text>
            </Box>
            <LegacyStack vertical spacing="tight">
              <Text variant="bodyMd" as="p">
                {group.sellingPlans.map(plan => plan.options[0]).join(", ")}
              </Text>
              {group.merchantCode ? (
                <Text variant="bodyMd" as="p" color="subdued">
                  {group.merchantCode}
                </Text>
              ) : null}
            </LegacyStack>
            <ProductsImages productIds={getAllProductsIds(group.enabledProductIdToVariantIdMap)} />
          </Grid>
          <Grid item xs={12} md={5}>
            <S.CountsWrapper>
              <LegacyCard>
                {group.subscriptionCount} {group.subscriptionCount == 1 ? "subscriber" : "subscribers"}
              </LegacyCard>
              <LegacyCard>
                {group.sellingPlanCount} {group.sellingPlanCount <= 1 ? "plan" : "plans"}
              </LegacyCard>
            </S.CountsWrapper>
          </Grid>
        </Grid>
      </LegacyCard>
    </S.ProgramCardWrapper>
  );
}
